import React, { useState } from "react"
import { navigate } from "gatsby"

import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardMedia from "@material-ui/core/CardMedia"
import Grid from "@material-ui/core/Grid"
import Pagination from "@material-ui/lab/Pagination"
import Skeleton from "@material-ui/lab/Skeleton"
import Chip from "@material-ui/core/Chip"

import {
  FaPaintBrush,
  FaLaptopCode,
  FaLanguage,
  FaStarOfLife
} from "react-icons/fa"
import { MdChildCare, MdBusinessCenter } from "react-icons/md"
import { FcSportsMode } from "react-icons/fc"
import {
  GiFamilyHouse,
  GiOpenBook,
  GiCardRandom,
  GiMaterialsScience,
  GiMountaintop
} from "react-icons/gi"
import { IoIosBody, IoMdAirplane } from "react-icons/io"
import { BsPencil, BsFillPersonFill } from "react-icons/bs"
import { GrHistory } from "react-icons/gr"
import { FiCloud, FiHelpCircle } from "react-icons/fi"

import Header from "../components/header"
import Footer from "../components/footer"

import { useBooks } from "../hooks/use-books"
import SEO from "../components/seo"

const bookCategoryIcons = {
  Adventure: <GiMountaintop size={20} />,
  Art: <FaPaintBrush size={20} />,
  "Biography & Autobiography": <BsFillPersonFill size={20} />,
  "Body, Mind & Spirit": <IoIosBody size={20} />,
  "Business & Economics": <MdBusinessCenter size={20} />,
  Children: <MdChildCare size={20} />,
  English: <FaLanguage size={20} />,
  "Family & Relationships": <GiFamilyHouse size={20} />,
  Fiction: <FiCloud size={20} />,
  History: <GrHistory size={20} />,
  Literature: <BsPencil size={20} />,
  "Non Fiction": <FaStarOfLife size={20} />,
  Novels: <GiOpenBook size={20} />,
  Others: <GiCardRandom size={20} />,
  Science: <GiMaterialsScience size={20} />,
  "Self Help": <FiHelpCircle size={20} />,
  "Sports & Recreation": <FcSportsMode size={20} />,
  Technology: <FaLaptopCode size={20} />,
  Travel: <IoMdAirplane size={20} />
}

export default function Books() {
  const booksPerPage = 24
  const [page, setPage] = useState(1)
  const [category, setCategory] = useState("")
  const books = useBooks()

  let booksToDisplay = books
  if (category !== "") {
    booksToDisplay = books.filter(book => book.categories.includes(category))
  }

  const handleBookClick = alias => {
    navigate("/books/" + alias, { state: { alias: alias } })
    setPage(1)
  }

  const handleCategoryClick = category => {
    setPage(1)
    setCategory(category)
  }

  return (
    <>
      <SEO />
      <Header />
      <div
        style={{
          marginTop: "75px",
          paddingBottom: "25px",
          backgroundColor: "whitesmoke"
        }}
      >
        <Container style={{ paddingTop: 16 }}>
          <Typography variant="h5" style={{ marginBottom: "8px" }}>
            {books.length === 0 ? "Loading all " : "Showing all "}
            {category === "" ? "books" : category + " books"}
          </Typography>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              marginBottom: 8
            }}
          >
            {Object.keys(bookCategoryIcons).map(book_category => (
              <Chip
                size="small"
                style={{ margin: "0px 4px 8px" }}
                label={book_category}
                icon={bookCategoryIcons[book_category]}
                variant={book_category === category ? "default" : "outlined"}
                color="primary"
                onClick={() => handleCategoryClick(book_category)}
              />
            ))}
          </div>
          <Grid container spacing={4}>
            {books.length === 0
              ? [...Array(booksPerPage)].map(temp => (
                  <Grid
                    container
                    item
                    xs={6}
                    sm={3}
                    md={3}
                    lg={2}
                    xl={2}
                    key={temp}
                  >
                    <Card style={{ borderRadius: 10, borderColor: "#496BA4" }}>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        height={250}
                        width={200}
                      />
                      <CardContent>
                        <Skeleton animation="wave" variant="text" height={25} />
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              : booksToDisplay
                  .slice(booksPerPage * (page - 1), booksPerPage * page)
                  .map(book => (
                    <Grid
                      container
                      item
                      xs={6}
                      sm={3}
                      md={3}
                      lg={2}
                      xl={2}
                      key={book.alias}
                    >
                      <Card
                        style={{ borderRadius: 10, borderColor: "#496BA4" }}
                      >
                        <CardActionArea
                          onClick={() => handleBookClick(book.alias)}
                        >
                          <CardMedia
                            component="img"
                            src={require("../images/books/" +
                              book.alias +
                              ".jpg")}
                          />

                          <CardContent
                            style={{ padding: "16px 16px 0px 16px" }}
                          >
                            <Typography>{book.title}</Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              marginBottom: "4px"
                            }}
                          >
                            {book.categories.map(book_category => (
                              <Chip
                                size="small"
                                style={{
                                  marginTop: "4px",
                                  marginRight: "4px"
                                }}
                                label={book_category}
                                variant={
                                  book_category === category
                                    ? "default"
                                    : "outlined"
                                }
                                color={"primary"}
                                icon={bookCategoryIcons[book_category]}
                                onClick={() =>
                                  handleCategoryClick(book_category)
                                }
                              />
                            ))}
                          </div>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
          </Grid>
          <Pagination
            style={{
              marginTop: "25px",
              justifyContent: "center",
              display: "flex"
            }}
            page={page}
            count={Math.ceil(booksToDisplay.length / booksPerPage)}
            color="primary"
            onChange={(event, page) => {
              setPage(page)
            }}
          />
        </Container>
      </div>
      <Footer />
    </>
  )
}
